import React from "react";

export const Shipping = (props) => {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="about-text">
                        <h2>Shipping Information:  </h2>


                        <p>We aim to provide a seamless shipping experience for your orders. Here's what you need to know:</p>

                        <b>1. Processing Time:</b>
                        <p>- Customized orders are typically processed within 10-12 business days.</p>

                        <b>2. Shipping Methods:</b>
                        <p>- We offer standard shipping for most products.</p>
                        <p>- Certain products may not require shipping and will be available for immediate download or pickup.</p>

                        <b>3. Shipping Costs:</b>
                        <p>- Shipping costs vary based on your location and the weight of the items in your order.</p>
                        <p>- Some products may qualify for free shipping.</p>

                        <b>4. Delivery Times:</b>
                        <p>- Standard shipping usually takes 10 days within India for customized products.</p>
                        <p>- Expedited shipping options are available for faster delivery.</p>

                        <b>5. Order Tracking:</b>
                        <p>- Once your order is shipped, you will receive a confirmation email with tracking information.</p>

                        <b>6. International Shipping:</b>
                        <p> - We offer international shipping for our global customers.</p>
                        <p> - Please note that customs duties and taxes may apply, and customers are responsible for these fees.</p>

                        <b>7. Products Exempt from Shipping:</b>
                        <p> - Certain products, such as digital downloads or services, may not require shipping.</p>
                        <p> - You'll be notified during the checkout process if any items in your order fall into this category.</p>

                        <b>8. Returns and Refunds:</b>
                        <p> - For information on returns and refunds, please refer to our <a href="#">Returns Policy.</a></p>

                        <p>If you have any specific questions or concerns regarding shipping, feel free to <a href="#">suppport@qr4.me</a>. We're here to assist you!</p>



                    </div>
                </div>
            </div>
        </div>
    );
};
