import React from "react";

export const Terms = (props) => {
  return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Terms and Conditions:  </h2>


              <p>Welcome to 1qr4.in.  This site is owned and managed by PayRu Fintech Private Limited.</p>

              <p>Before using our website, please take a moment to review our terms and conditions:</p>

              <b>1. Acceptance of Terms:</b>
              <p> By accessing or using our website, you agree to comply with and be bound by these terms and conditions.</p>

              <b>2. se of the Website:</b>
              <p> You agree to use this website for lawful purposes only and in a manner consistent with all applicable local, national, and international laws and regulations.</p>

              <b>3. Intellectual Property:</b>
              <p> All content on this website, including text, graphics, logos, images, and software, is the property of PayRu Fintech Private Limited and is protected by intellectual property laws.</p>

              <b>4. User Accounts:</b>
              <p> If you create an account on our website, you are responsible for maintaining the confidentiality of your account information and password. You agree to accept responsibility for all activities that occur under your account.</p>

              <b>5. Product Information:</b>
              <p> We strive to provide accurate and up-to-date information about our products. However, we do not warrant the accuracy, completeness, or reliability of any product descriptions or other content on the site.</p>

              <b>6. Pricing and Payment:</b>
              <p> Prices for products are subject to change without notice. We reserve the right to refuse or cancel orders at our discretion.</p>

              <b>7. Privacy Policy:</b>
              <p> Your use of this website is also governed by our <a href="#"> Privacy Policy</a>, which outlines how we collect, use, and protect your personal information.</p>

              <b>8. Limitation of Liability:</b>
              <p> PayRu Fintech Private Limited is not liable for any direct, indirect, incidental, special, or consequential damages that may result from your use of, or inability to use, this website.</p>

              <b>9. Termination:</b>
              <p> We reserve the right to terminate or suspend your account and access to the website without prior notice for any violation of these terms.</p>

              <b>10. Updates and Modifications:</b>
              <p> We may update or modify these terms and conditions at any time. It is your responsibility to review them periodically.</p>

              <b>11. Contact Us:</b>
              <p> If you have any questions or concerns about these terms and conditions, please <a href="#" onClick={() => window.open("https://1qr4.in/contact/", "_blank")}> contact us.</a></p>

              <p>Thank you for choosing PayRu Fintech.com. We appreciate your cooperation in adhering to these terms and conditions for a positive online experience.</p>

            </div>
          </div>
        </div>
      </div>
  );
};
