import React, { useEffect, useState } from "react";
import { Navigation } from "./components/navigation";
import { Payment } from "./components/payment";
import { Terms } from "./components/terms";
import { Shipping } from "./components/shipping";
import { Privacy } from "./components/privacy";
import { Landing } from "./components/landing";
import { Product } from "./components/product";
import SmoothScroll from "smooth-scroll";
import { Footer } from "./components/footer"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Return } from "./components/return";
import JsonData from "./data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
      setLandingPageData(JsonData);
  }, []);

  return (
    <div>


      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" >
            <Route index element={<Landing />} />
            <Route path="/" element={<Landing />} />
            <Route path="payment" element={<Payment />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/return" element={<Return />} />
            <Route path="/products" element={<Product />} />
            <Route path="/shipping" element={<Shipping />} />
          </Route>
        </Routes>
        <Footer data={landingPageData.Contact} />
      </BrowserRouter>
    </div>
  );
};

export default App;
