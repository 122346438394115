import React, { useEffect, useState } from "react";
import JsonData from "../data/data.json";

export const ProductCard = () => {

    const [pricingData, setLandingPageData] = useState([]);
    const [isIndex, setIndex] = useState("");
    useEffect(() => {
        setLandingPageData(JsonData.Pricing);
    }, []);


    const handleWhatsapp = (text) => {
        const whatsappURL = `https://api.whatsapp.com/send?phone=9833303311&text=${encodeURIComponent(text)}`;
        window.open(whatsappURL, '_blank');
    }

    return (

        <>
            <section id="pricing" className="pricing sections-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>Pricing</h2>
                    </div>

                    <div className="row gy-4" data-aos="zoom-out" data-aos-delay="100">
                        {pricingData ? pricingData?.map((d, i) => (
                            <div key={i} className="col-lg-4" onMouseEnter={() => { setIndex(i) }} onMouseLeave={() => { setIndex("") }} >
                                <div className={`pricing-item ${isIndex === i ? "featured" : ""}`}>
                                    <h3>{d.title}</h3>
                                    <div style={{ margin: "30px auto 30px auto",display:"flex",justifyContent:"center" }}>
                                        <img src={d.icon} style={{ height: 150 }} />
                                    </div>
                                    {d.price ? <h4>{d.price}<span> / Year</span></h4> : ""}
                                    <div style={{ height: "200px" }}>
                                        <ul>
                                            {d.text?.split(".").map((res, i) => (<li> {res}</li>))}
                                        </ul>
                                    </div>
                                    <div style={{ cursor: "pointer" }} className="text-center"><a onClick={() => handleWhatsapp(d.getStarted)} className="buy-btn">Get Started</a></div>
                                </div>
                            </div>
                        )) : ""}
                    </div>

                </div>
            </section>
        </>
    )
}
